.mr-b-20 {
    margin-bottom: 20px;
}

.mb-14 {
    margin-bottom: 14px;
}

/* .selectFroCoutnry .css-2b097c-container {
    margin-left: 35px;
} */

.selectFroCoutnry .css-1okebmr-indicatorSeparator {
    display: none;
}

.selectFroCoutnry .css-g1d714-ValueContainer {
    height: 56px;
    padding: 8px 17px;

    font-size: 16px;
    font-family: 'NunitoSans Regular';
    color: rgba(0, 0, 0, 0.85);
}

.selectFroCoutnry .css-1hwfws3 {
    height: 56px;
    padding: 8px 17px;
    font-size: 16px;
    font-family: 'NunitoSans Regular';
    color: rgba(0, 0, 0, 0.85);
}

/* .SelectForState .css-2b097c-container {
    width: 94%;
} */

.SelectForState .css-1okebmr-indicatorSeparator {
    display: none;
}

.SelectForState .css-g1d714-ValueContainer {
    height: 56px;
    padding: 8px 17px;
    font-size: 16px;
    font-family: 'NunitoSans Regular';
    color: rgba(0, 0, 0, 0.85);
}

.SelectForState .css-1hwfws3 {
    height: 56px;
    padding: 8px 17px;
    font-size: 16px;
    font-family: 'NunitoSans Regular';
    color: rgba(0, 0, 0, 0.85);
}

.Roles-Add-Pages-MainClass {
    opacity: 0.5;
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #000;
    margin-left: 17px;
    margin-right: 2px;
}

.Chervon-Roles-Pages {
    width: 4px;
    height: 8px;
    opacity: 0.5;
    margin-right: 6px;
}

.Roles-Pages-Settings {
    opacity: 0.5;
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #000;
    margin-right: 5px;
}

.Edit-profile-Roles {
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #333;
}

.BBOrder-Div-Add-Roles-Pages {
    width: 100%;
    height: auto;
    margin: auto;
    background-color: #fff;
    margin-bottom: 40px;
}

.Roles-text-Add-Pages-Class-div {
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 61px;
}

.Roles-text-Add-Buttons {
    margin-right: 27px;
}

.Roles-text {
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #313131;
    display: block;
}

.mt-top-16 {
    margin-top: 16px;
}

.Roles-input-Fields .ant-input {
    height: 56px;
    margin: 12px 0px 17px 0px;
    padding: 17px 0px 17px 17px;
    border-radius: 4px;
    border: solid 1px #dadada;
    background-color: #fff;
    width: 100%;
}

.contact-number-input .form-control {
    width: 100% !important;
    height: 56px !important;
}

.Roles-input-Fields .ant-input:placeholder-shown {
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #707070 !important;
}

.Address-Fields .ant-input {
    height: 56px;
    margin: 12px 0px 16px 0px;
    padding: 17px 0px 17px 17px;
    border-radius: 4px;
    border: solid 1px #dadada;
    background-color: #fff;
    width: 100%;
}

.Address-Fields .ant-input:placeholder-shown {
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #707070 !important;
}

.mr-top-13 {
    margin-top: 13px;
}


.Roles-Pages {
    font-family: 'NunitoSans Bold';
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    color: #000;
}

.positionicon {
    position: relative;
    margin-bottom: 60px;
}

.profile-img {
    margin: auto;
    height: 110px;
    width: 110px;
}

.edit-icon {
    position: absolute;
    height: 23px;
    right: 0;
    top: 95px;
}

.Roles-Button .Cancel-button .Button-Cancel {
    margin-right: 20px;
    width: 142px;
    height: 60px;
    padding: 17px 40px 16px;
    border-radius: 6px;
    background-color: #f3f5fa;
    cursor: pointer;
}

.Roles-Button .Cancel {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #5a5a5a;
}

.Roles-Button .Create-button .Button-Create {
    cursor: pointer;
    width: 140px;
    height: 60px;
    padding: 17px 19px 16px 40px;
    border-radius: 6px;
    background-color: #296ce6;
}

.Roles-Button .Create {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #ffffff;
}

.mr-bottom-34 {
    margin-bottom: 34px;
}