.User-Managemenet-Serach .ant-select {
    margin-right: 12px !important;
}

.User-Managemenet-Serach .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid white !important;
    border-radius: 6px !important;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 38px !important;
    padding: 6px 11px 0px 10px !important;
}

.User-Managemenet-Serach .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    font-size: 14px;
    font-family: 'NunitoSans SemiBold';
    color: black;

}

.User-Managemenet-Serach .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    min-width: 0;
    width: 182px !important;
    height: 40px !important;
    margin: 0px 0px 0px 200px !important;
    padding: 9px 10px 9px 12px !important;
    border-radius: 6px !important;
    background-color: #fff !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border: 1px solid #fff !important;
}

.err-message-for-usermanagement {
    color: red;
    /* margin-bottom: 10px; */
}

.add-more-email-ids {
    margin-top: -33px;
    margin-right: 15px;
    cursor: pointer;
    width: 100%;
    height: 33px;
    /* margin-bottom: 10px; */
    padding: 5px 13px 6px 10px;
    border-radius: 6px;
    background-color: #296ce6;
}

.User-Managemenet-Serach .ant-input {
    font-size: 16px;
    font-family: 'NunitoSans Regular';
    color: #707070 !important;
    height: 19px !important;
}

.User-Managemenet-payment .ant-select {
    margin-right: 12px !important;
}

.User-Managemenet-payment .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid white !important;
    border-radius: 6px !important;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 38px !important;
    padding: 6px 11px 0px 10px !important;
}

.User-Managemenet-payment .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    font-size: 14px;
    font-family: 'NunitoSans SemiBold';
    color: black;

}

.User-Managemenet-payment .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    min-width: 0;
    width: 310px !important;
    height: 40px !important;
    margin: 0px 0px 0px 200px !important;
    padding: 9px 10px 9px 12px !important;
    border-radius: 6px !important;
    background-color: #fff !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border: 1px solid #fff !important;
}

.User-Managemenet-payment .ant-input {
    font-size: 16px;
    font-family: 'NunitoSans Regular';
    color: #707070 !important;
    height: 19px !important;
}


.mr-b-23 {
    margin-bottom: 23px;
}


.table-User-magament .ant-table-column-sorters {
    padding: 16px 20px;
}

.table-User-magament .ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    padding: 16px 20px;
}

.table-User-magament .ant-table-container table>thead>tr th {
    border-top-left-radius: 2px;
    height: 22px;
    margin: 0 7.2px 16px 20px !important;
    font-family: 'NunitoSans Bold';
    font-size: 16px;
    font-weight: bold;

    line-height: 1.38;

    color: #333;
}

.table-User-magament td.ant-table-column-sort {
    background: white;
}


.table-User-magament .ant-table-pagination.ant-pagination {
    margin-top: 10px;
    margin-bottom: 0px;
}

.table-User-magament .ant-table-thead>tr>th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;

    background: white;
}

.table-User-magament .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: white;
}

.table-User-magament .ant-table-tbody>tr.ant-table-row:hover>td {
    background: white;
}

.User-Mangement-Page {
    font-family: 'NunitoSans Bold';
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    color: #000;
}

.delete-icon-User {
    width: 18px;
    height: 20px;
    object-fit: contain;
    cursor: pointer;
}

.ant-switch-checked {
    background-color: #296ce7 !important;
}

.Plan-add-image-user {
    width: 14px;
    height: 14px;
}

.add-plan-buttons-user {
    font-family: 'NunitoSans Bold';
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    margin-left: 6px;
}

.Day-week-buttons-Price-user .ant-btn {
    height: 40px;
    padding: 11px 9px 10px 11px;
    border-radius: 6px;
    background-color: #296ce7;
    margin-left: 13px;
}

/* View Modal */

@media (max-width: 991px) {
    .User-Managemenet-Serach .ant-input-affix-wrapper {
        width: 100% !important;
        margin: 10px 7px 10px 0px !important;
    }

    .Day-week-buttons-Price-user .ant-btn {
        width: 100%;
        margin-left: 0px;
    }

    .User-Managemenet-payment .ant-input-affix-wrapper {
        margin: 7px 0px 0px 0px !important;
        width: 100% !important;
    }
}