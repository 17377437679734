
.View-Details-Plan-Border-Card
{
    width: 100%;
    height: auto;
    padding: 41px 35px 47px;
    background-color: #fff;
    margin: 0px 20px 0px 0px;
    padding: 34.5px 34px 10px 35.5px;
}
.View-details-Price-tex-Card
{
    margin: 5.5px 0px 12px 0;
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #525252;
}
.price-Card
{  
    margin: 12px 0px 40px 0;
    font-family: 'NunitoSans SemiBold';
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    color: #000;
}
.edit-profile-Card
{
  font-family: 'NunitoSans SemiBold';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: #fff;
}
.Vieew-Details-Price-plan-Days-Card .ant-btn {
    /* width: 164px; */
    height: 50px;
    padding: 14px 21px 14px 21px !important;
    border-radius: 6px;
    background-color: #296ce6;
    margin-bottom: 12px;
}

.Billing-note
{
color: red;
font-family: 'NunitoSans SemiBold';
font-size: 14px;
}