/* THIS ALL FOR  AVENIR STYLE */
a{
  text-decoration: none !important;
}
@font-face {
  font-family: 'AAvenirNext Bold';
  src:url('../fonts/AvenirNextLTPro-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext Demi';
  src:url('../fonts/AvenirNextLTPro-Demi.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext Heavy';
  src:url('../fonts/AvenirNextLTPro-Heavy.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext Medium';
  src:url('../fonts/AvenirNextLTPro-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext Regular';
  src:url('../fonts/AvenirNextLTPro-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* THIS ALL FOR  INTER FONT STYLE */

@font-face {
    font-family: 'Inter Black';
    src:url('../fonts/Inter-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter Bold';
    src:url('../fonts/Inter-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter ExtraBold';
    src:url('../fonts/Inter-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter ExtraLight';
    src:url('../fonts/Inter-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter Light';
    src:url('../fonts/Inter-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter Medium';
    src:url('../fonts/Inter-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter Regular';
    src:url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter Semibold';
    src:url('../fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter Thin';
    src:url('../fonts/Inter-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
 
  /* THIS ALL FOR NATUS SANCS FONT  */

  
@font-face {
  font-family: 'NunitoSans Black';
  src:url('../fonts/NunitoSans-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans BlackItalic';
  src:url('../fonts/NunitoSans-BlackItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans Bold';
  src:url('../fonts/NunitoSans-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans ExtraBold';
  src:url('../fonts/NunitoSans-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans ExtraBoldItalic';
  src:url('../fonts/NunitoSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans ExtraLight';
  src:url('../fonts/NunitoSans-ExtraLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans ExtraLightItalic';
  src:url('../fonts/NunitoSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans Italic';
  src:url('../fonts/NunitoSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans Light';
  src:url('../fonts/NunitoSans-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans LightItalic';
  src:url('../fonts/NunitoSans-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans Regular';
  src:url('../fonts/NunitoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans SemiBold';
  src:url('../fonts/NunitoSans-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans SemiBoldItalic';
  src:url('../fonts/NunitoSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

  /* THIS ALL FOR HELVETICA NEUE  */

  @font-face {
    font-family: 'HelveticaNeue';
    src:url('../fonts/HelveticaNeue.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueBold';
    src:url('../fonts/helvetica-neue-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueLight';
    src:url('../fonts/helvetica-neue-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueMedium';
    src:url('../fonts/helvetica-neue-medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueRegular';
    src:url('../fonts/helvetica-neue-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


  .footer-styling {
    margin: 16px 0px 12px 0px;
    opacity: 0.5;
    font-family: 'NunitoSans Regular';
    font-size: 12px;
    line-height: 1.33;
    color: #000;
    display: inline-block;
}



  .btn-message .ant-btn {
    /* width: 200px; */
    height: 60px;
    margin: 39px 1px 0 169px;
    padding: 18px 34px 17px;
    border-radius: 6px;
    background-color: #296ce7 !important;
  }
  
  .buttton-row {
    padding-left: 15px;
    padding-right: 15px;
    justify-content: end;
  }
  
  .send-message-text {
    height: 25px;
    font-family: 'NunitoSans Regular';
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }
  
  .Enter-name-Contact-us-form .ant-input {
    margin-bottom: 10px !important;
    height: 60px !important;
    border-radius: 4px !important;
    border: solid 1px #dadada !important;
    background-color: #fff;
  
  }
  
  .Enter-name-Contact-us-form .ant-input:placeholder-shown {
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    padding: 20px 0px 18px 20px;
    color: #707070 !important;
  
  }
  
  .Add-DescFor-Contactus-files .ant-input {
    height: 220px !important;
    margin-bottom: 10px !important;
    border-radius: 4px !important;
    border: solid 1px #dadada !important;
    background-color: #fff;
    resize: none;
    padding: 17px 0px 18px 20px;
  
  }
  
  .Add-DescFor-Contactus-files .ant-input:placeholder-shown {
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    padding: 17px 0px 18px 20px;
    color: #707070 !important;
  }
  
  .Contactus-roles {
    width: 100%;
    height: auto;
    background-color: #fff;
    padding: 19px 42.5px 24.6px 39.5px;
    margin-bottom: 10px;
  }
  
  .Contact-Admin-Details {
    font-family: 'NunitoSans Bold';
    font-size: 20px;
    font-weight: bold;
    line-height: 1.35;
    color: #000;
  }
  
  .Conatcutu-plan {
    margin: 21px 0px 51px 0;
    opacity: 0.5;
    font-family: 'NunitoSans Regular';
    font-size: 18px;
    line-height: 2;
    color: #000;
  }


  .Note-serach {
    color: black;
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 24px;
}

.text-for-all {
    font-family: 'NunitoSans Regular';
    font-size: 14px;
    line-height: 1.36;
    color: #5b5b5b;
}

.Roles-Mangement-Page {
    font-family: 'NunitoSans Bold';
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    color: #000;
}

.Roles-Mangement-Page-Card {
    font-family: 'NunitoSans Bold';
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    color: #000;
}

.Plan-add-image-Roles {
    width: 14px;
    height: 14px;
}

.add-plan-buttons-Roles {
    font-family: 'NunitoSans Bold';
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    margin-left: 6px;
}
