.Add-events-Buttons .Cancel-button-Events .Button-Cancel-Events {
    margin-right: 20px;
    width: 142px;
    height: 60px;
    padding: 17px 40px 16px;
    border-radius: 6px;
    background-color: #f3f5fa;
    cursor: pointer;
}

.chosen-color {
    margin-top: 7px;
    margin-left: 10px;
}

.Change-colors-text {
    width: 18px;
    height: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.red {
    color: red !important;
}

.green {
    color: green !important;
}


.mr-bottom-13 {
    margin-bottom: 13px;
}

.Add-events-Buttons .Cancel-Events {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #5a5a5a;
}

.Add-events-Buttons .Create-button-Events .Button-Create-Events {
    cursor: pointer;
    width: 140px;
    height: 60px;

    padding: 17px 19px 16px 40px;
    border-radius: 6px;
    background-color: #296ce6;
}

.Add-events-Buttons .Create-Events {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #ffffff;
}

.AddingEvents {
    margin-right: 20px;
}



.please-consider {
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #333;
    opacity: 0.5;
}

.selection-users {
    width: 22px;
    height: 18px;
    object-fit: contain;
}

.Add-Events-Files .ant-input {
    margin-top: 12px !important;
    margin-bottom: 15px !important;
    border-radius: 4px !important;
    border: solid 1px #dadada !important;
    background-color: #fff;
    resize: none;
    height: 100px !important;
}

.Add-Events-Files .ant-input:placeholder-shown {
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #707070 !important;
}

.Add-Events-Files textarea.ant-input {
    padding: 20px !important;
}

.information-logo {
    width: 16px;
    height: 16px;
    margin: 3px 8px 3px 0;
    object-fit: contain;
}

.mr-bottom-40 {
    margin-bottom: 7px;
}

.mr-b-20 {
    margin-bottom: 20px;
}

.home-Ad-Events_pages {
    opacity: 0.5;
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #000;
    margin-left: 17px;
    margin-right: 2px;
}

.chevron-right-1-Ad-Events_pages {
    width: 4px;
    height: 8px;
    opacity: 0.5;
    margin-right: 6px;
}

.Priceandplan-Ad-Events_pages {
    opacity: 0.5;
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #000;
    margin-right: 5px;
}

.create-plan-Ad-Events_pages {
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #333;
}

.Border-div-Ad-Events_pages {
    width: 100%;
    height: auto;
    margin: auto;
    background-color: #fff;
    margin-bottom: 14px;
    padding-bottom: 91px;
}

.main-div-class-plan-Ad-Events_pages {
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 21px;
}

.EventsName-text {
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #313131;
    display: block;
}

.Events-display {
    font-family: 'NunitoSans SemiBold';
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 1.38;
    color: #313131;
    display: block;
}

.selection-user-inputs .ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
    height: 56px;
    padding-left: 19px;
    padding-top: 17px;
    padding-bottom: 17px;
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #313131;
}

.selection-user-inputs .ant-select-selector {
    height: 56px;
    padding-left: 19px;
    padding-top: 17px;
    padding-bottom: 17px;
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #313131;
}

.Events-Addd-Users {
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #313131;
    display: block;
    margin-top: 17px;
    margin-bottom: 11px;
}

.Events-Date-Picker_start .ant-picker {
    width: 217px;
    height: 56px;
    margin: 12px 20px 0 0;
    padding: 17px 0px 17px 14px;
    border-radius: 4px;
    border: solid 1px #dadada;
    background-color: #fff;
    display: inline-block;
}

.Events-Date-Picker_start .ant-picker-suffix {
    display: none;
}

.Events-Date-Picker_start .time-evnets-date-picker .ant-picker-input>input:placeholder-shown {

    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #313131;
}

.time-evnets-date-picker {
    width: 100%;
}

.time-evnets-date-picker .ant-picker {
    width: 100%;
    height: 56px;
    margin: 12px 0px 0 0;
    padding: 17px 0px 17px 14px;
    border-radius: 4px;
    border: solid 1px #dadada;
    background-color: #fff;
    display: inline-block;
}

.time-evnets-date-picker .ant-picker-suffix {
    display: none;
}


.Date-picker-for-ends .ant-picker {
    width: 236px;
    height: 56px;
    margin: 12px 20px 0 0;
    padding: 17px 0px 17px 14px;
    border-radius: 4px;
    border: solid 1px #dadada;
    background-color: #fff;
    display: inline-block;
}

.Date-picker-for-ends .ant-picker-suffix {
    display: none;
}

.Date-picker-for-ends .time-evnets-date-picker .ant-picker-input>input:placeholder-shown {

    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #313131;
}


.Events-InputFields-Data .ant-input {
    height: 56px;
    margin: 12px 0px 40px 0px;
    padding: 17px 0px 17px 17px;
    border-radius: 4px;
    border: solid 1px #dadada;
    background-color: #fff;
    width: 100%;

}

.Events-InputFields-Data .ant-input:placeholder-shown {
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #707070 !important;
}

.hr-styling {
    margin-top: 18px;
    margin-bottom: 20px;
    height: 1px;
    border-radius: 6px;
    opacity: 0.5;
    color: #dadada;
}

.First-Add-Client-Page {
    font-family: 'NunitoSans Bold';
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    color: #000;
}



/* ADD MODAL  */

.Modal-Add-Modetors .ant-modal-close-x {
    margin-top: 40px;
    font-size: 23px;
    margin-right: 29px;
    color: #5b5b5b;
}

.Modal-Add-Modetors .ant-modal-body {
    padding-left: 50px;
    padding-top: 36px;
    padding-right: 48.9px;
}

.Add-modetors-Customers {
    font-family: 'NunitoSans Bold';
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    margin-bottom: 36px;
    color: #000;
}


.Modal-Add-Modetors .ant-modal-content {

    border-radius: 10px;
}


.Serach-Modetors {

    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #313131;
}

.Serach-Modetors-main .ant-input {
    width: 100%;
    height: 56px;
    margin: 10px 14px 22px 0;
    padding: 17px 0px 17px 19px;
    border-radius: 4px;
    border: solid 1px #dadada;
    background-color: #fff;
}

.serach-modetors-customers .ant-input:placeholder-shown {
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #313131;
}

.Select-button-for-moderators .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {

    height: 56px;
    padding: 13px 16px;

}

.Select-button-for-moderators .ant-select {
    width: 205px;
    height: 49px;
    margin: 9px 0 0px 0px;

    background-color: #fff;
}

.Select-button-for-moderators .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 4px;
    padding-top: 3px;

    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #000;

}

.modetors-Table-styling {

    /* height: 422px; */
    margin: 0px 0px 25px 0;
    padding: 2px 0 0;
    border-radius: 4px;
    border: solid 1px #dadada;
    background-color: #fff;
}

.modetors-Table-styling .ant-table-thead>tr>th {
    color: rgba(0, 0, 0, 0.85) !important;
    font-weight: 500;
    background: white;
}

.modetors-Table-styling .ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    padding-top: 25px !important;
    padding-bottom: 23px !important;
}

.modetors-Table-styling .ant-table-tbody>tr.ant-table-row:hover>td {
    background: white;
}

.modetors-Table-styling .ant-table-thead>tr>th {
    background: white;
    border-bottom: 1px solid #e9e9e9;
}

.modetors-Table-styling .ant-table-tbody>tr.ant-table-row-selected>td {
    background: #f5f9ff !important;

}

.modetors-Table-styling .ant-table-thead>tr>th {

    font-family: 'NunitoSans SemiBold';
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;

    color: #000 !important;
}

.modetors-Table-styling .ant-table-pagination.ant-pagination {
    margin: 16px 0;
    display: none;
}

.modetors-Table-styling .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #296ce6;
    border-color: #296ce6;
}

.modetors-Table-styling .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-56%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
    top: 52%;
    left: 24%;
    width: 6px;
    height: 9px;
    background-color: #296ce6;
}

.modetors-Table-styling .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: #296ce6;
    border-color: #296ce6;
}

.Modal-Add-Modetors .ant-modal-footer {
    display: none;
}

.Modetor-Button .Modetors-Cancel-Button .Modetors-Cancel {
    /* margin-left: 614px; */
    margin-right: 20px;
    width: 142px;
    height: 60px;
    padding: 17px 40px 16px;
    border-radius: 6px;
    background-color: #f3f5fa;
    cursor: pointer;

}

.Modetor-Button .CancelButtonModetors {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #5a5a5a;
}

.Modetor-Button .Create-button-Mode .Button-Create-Mode {
    cursor: pointer;
    width: 140px;
    height: 60px;
    padding: 17px 50px 16px 51px;
    border-radius: 6px;
    background-color: #296ce6;
}

.Modetor-Button .Create-Mode {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #ffffff;
}


@media(max-width:767px) {
    .color-top-10 {
        margin-top: 10px !important;
    }
}

@media(max-width:991px) {
    .Events-Date-Picker_start .ant-picker {
        width: 100%;
    }

    .Date-picker-for-ends .ant-picker {
        width: 100%;
    }

    .Modal-Add-Modetors-Others .ant-modal-body {
        padding-left: 19px;
        padding-top: 35px;
        padding-right: 24.9px;
    }

    .Profile-Assign-modal .ant-modal-body {
        padding-left: 19px;
        padding-top: 35px;
        padding-right: 24.9px;
    }

    .Modetor-Button .Modetors-Cancel-Button .Modetors-Cancel {
        height: 49px !important;
        padding: 9px 37px 11px !important;
    }

    .Modetor-Button .Create-button-Mode .Button-Create-Mode {
        height: 49px !important;
        padding: 9px 49px 11px !important;
    }

    .Profile-Page-Modal .Cancel-button-Profile .Button-Cancel-Profile {
        height: 49px !important;
        padding: 9px 37px 11px !important;
    }
    .Profile-Page-Modal .Create-button-Profile .Button-Create-Profile {
        height: 49px !important;
        padding: 9px 37px 11px !important;
    }
}