.Others-roles {
  width: 100%;
  height: auto;
  margin: 0px 0px 19px;
  background-color: #fff;
  padding-top: 19px;
  padding: 30px 42.5px 24.6px 39.5px;
}


.Others-Mangement-Page {
  font-family: 'NunitoSans Bold';
  font-size: 26px;
  font-weight: bold;
  line-height: 1.35;
  color: #000;
}

.Admin-Details {

  font-family: 'NunitoSans Bold';
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  color: #000;
}

.Add-others {
  width: 72px;
  padding: 4px 9px 4px 10px;
  border-radius: 4px;
  background-color: #296ce7;
  cursor: pointer;
}

.Add-Buttons {
  font-family: 'NunitoSans SemiBold';
  font-size: 16px;
  line-height: 1.38;
  color: #fff;
  margin-left: 5px;
}

.plus-button {
  width: 12px;
}

.adding-modetar {
  margin-top: 29px;
  margin-bottom: 24px;
}

.Shijin-Suresh {
  margin: 12px 0px 12px 20px;
  font-family: 'NunitoSans SemiBold';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: #000;
}

.modetaro {
  width: 85px;
  height: 30px;
  /* margin-top: 39px; */
  padding: 6px 11px 5px 12px;
  border-radius: 5px;
  background-color: #ece0fe;
}

.Moderator-text {
  font-family: 'NunitoSans Regular';
  font-size: 14px;
  line-height: 1.36;
  color: #296ce6;
}

.Moderater-space {
  position: relative;
  width: 108px !important;
  padding: 9px 10px 9px 12px !important;
  display: inline-flex;
}

.img-Icon-material-close {
  width: 14px;
  height: 14px;
  margin: 5px 0 16px 16px;
}

.Pricing-plan {
  margin: 11px 0px 20px 0;
  font-family: 'NunitoSans Bold';
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  color: #000;
}

.Pricepaln-table {
  width: 100%;
  height: auto;
  margin: 20px 0 0;

  border-radius: 5px;
  border: solid 1px #e5ecfc;
  background-color: #fff;
}

.Teams {
  font-family: 'NunitoSans Bold';
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  color: #000;
}

.user-mo {
  font-family: 'NunitoSans SemiBold';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  margin-right: 16px;
  color: #000;
}

.For-small-and-medium-sized-businesses {
  margin: 7px 0px 19px 0px;
  opacity: 1;
  font-family: 'NunitoSans Regular';
  font-size: 16px;
  line-height: 1.38;
  color: #000;
  margin-left: 16px;
}

.TOP-FEATURE {

  margin: 19px 0px 0px 0px;
  font-family: 'NunitoSans Regular';
  font-size: 14px;

  line-height: 1.36;

  color: #525252;
  margin-left: 16px;
}

#tick-mark {
  position: relative;
  display: inline-block;
  width: 13.3px;
  margin-top: 8px;
  margin-left: 16px;
  height: 16.8px;


}

#tick-mark::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 50%;
  width: 3px;
  background-color: #296ce7;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

#tick-mark::after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: #296ce7;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

.Schedule-unlimited-on-site-andor-virtual-event-tasks {
  margin: 10px 0px 10px 12.2px;
  font-family: 'NunitoSans Regular';
  font-size: 14px;
  line-height: 1.36;
  color: #000;
}

.Updgrade-button {
  width: 120px;
  height: 40px;
  margin: 0px 16px 6px 10px;
  padding: 5px 15px 11px;
  cursor: pointer;
  float: right;
  border-radius: 5px;
  background-color: #296ce7;
}

.Upgrade-plan {
  font-family: 'HelveticaNeueMedium';
  font-size: 14px;
  line-height: 2.5;
  color: #fff;
}

.style-hr {
  margin-top: 57px;
  margin-bottom: 0px !important;
}

.just-for-padding {
  padding: 16px 16px 16px 16px;
}

.download-invoice {
  font-family: 'NunitoSans Regular';
  font-size: 16px;
  line-height: 1.38;
  color: #000;
  margin-left: 10px;
}

.due-date {
  font-family: 'NunitoSans Regular';
  font-size: 16px;
  line-height: 1.38;
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 12px;
  color: #000;
  margin-right: 15px;
}

.bckcolor {
  background-color: #f3f5fa;
}

.downloadarea {
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 12px;
}

.Mtext {
  margin-left: 16px;
  margin-top: 16px;
}

/* ADD MODAL  */

.Modal-Add-Modetors-Others .ant-modal-close-x {
  margin-top: 26px;
  font-size: 20px;
  width: 19px;
  margin-right: 22px;
  color: #5b5b5b;
}

.Modal-Add-Modetors-Others .ant-modal-body {
  padding-left: 50px;
  padding-top: 36px;
  padding-right: 48.9px;
  padding-bottom: 87px;
}

.Add-modetors-Customers {
  font-family: 'NunitoSans Bold';
  font-size: 26px;
  font-weight: bold;
  line-height: 1.35;
  margin-bottom: 36px;
  color: #000;
}


.Modal-Add-Modetors-Others .ant-modal-content {

  border-radius: 10px;
}


.Serach-Modetors {
  font-family: 'NunitoSans SemiBold';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: #313131;
}

.Serach-Modetors-main .ant-input {
  width: 100%;
  height: 56px;
  margin: 10px 14px 22px 0;
  padding: 17px 0px 17px 19px;
  border-radius: 4px;
  border: solid 1px #dadada;
  background-color: #fff;
}

.serach-modetors-customers .ant-input:placeholder-shown {
  font-family: 'NunitoSans Regular';
  font-size: 16px;
  line-height: 1.38;
  color: #313131;
}

.Select-button-for-moderators .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {

  height: 56px;
  padding: 13px 16px;

}

.Select-button-for-moderators .ant-select {
  width: 205px;
  height: 49px;
  margin: 9px 0 0px 0px;

  background-color: #fff;
}

.Select-button-for-moderators .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 4px;
  padding-top: 3px;

  font-family: 'NunitoSans SemiBold';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: #000;

}

.modetors-Table-styling {

  /* height: 422px; */
  margin: 0px 0px 25px 0;
  padding: 2px 0 0;
  border-radius: 4px;
  border: solid 1px #dadada;
  background-color: #fff;
}

.modetors-Table-styling .ant-table-thead>tr>th {
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 500;
  background: white;
}

.modetors-Table-styling .ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
  padding-top: 25px !important;
  padding-bottom: 23px !important;
}

.modetors-Table-styling .ant-table-tbody>tr.ant-table-row:hover>td {
  background: white;
}

.modetors-Table-styling .ant-table-thead>tr>th {
  background: white;
  border-bottom: 1px solid #e9e9e9;
}

.modetors-Table-styling .ant-table-tbody>tr.ant-table-row-selected>td {
  background: #f5f9ff !important;

}

.modetors-Table-styling .ant-table-thead>tr>th {

  font-family: 'NunitoSans SemiBold';
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;

  color: #000 !important;
}

.modetors-Table-styling .ant-table-pagination.ant-pagination {
  margin: 16px 0;
  display: none;
}

.modetors-Table-styling .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #296ce6;
  border-color: #296ce6;
}

.modetors-Table-styling .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-56%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
  top: 52%;
  left: 24%;
  width: 6px;
  height: 9px;
  background-color: #296ce6;
}

.modetors-Table-styling .ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #296ce6;
  border-color: #296ce6;
}

.Modal-Add-Modetors-Others .ant-modal-footer {
  display: none;
}

.Modetor-Button .Modetors-Cancel-Button .Modetors-Cancel {

  width: 142px;
  height: 60px;
  padding: 17px 40px 16px;
  border-radius: 6px;
  background-color: #f3f5fa;
  cursor: pointer;

}

.Modetor-Button .CancelButtonModetors {
  width: 60px;
  height: 27px;
  font-family: 'NunitoSans Regular';
  font-size: 20px;
  line-height: 1.35;
  color: #5a5a5a;
}

.Modetor-Button .Create-button-Mode .Button-Create-Mode {
  cursor: pointer;
  width: 140px;
  height: 60px;
  padding: 17px 50px 16px 51px;
  border-radius: 6px;
  background-color: #296ce6;
}

.Modetor-Button .Create-Mode {
  width: 60px;
  height: 27px;
  font-family: 'NunitoSans Regular';
  font-size: 20px;
  line-height: 1.35;
  color: #ffffff;
}

@media(max-width:991px) {
  .Admin-Details {
    margin-bottom: 10px;
  }

  .Shijin-Suresh {
    margin: 12px 0px 12px 0px;
  }
}