.ant-table-measure-row {
    display: none;
}
.showbefore {
    display: none;
}

.color-red {
    color: red !important;
}

.ml-15 {
    margin-left: 15px;
}

.breadcumbs-design {
    display: block;
    margin-top: 7px;
}

.err-message-email {
    color: red;
    margin-bottom: 10px;
}

.login-section-wrappers {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    background-color: #fff;
}

.brand-wrapper-login {
    padding-top: 60px;
    padding-right: 276.5px;
    padding-left: 110px;
    padding-bottom: 20px;
}

.email-address-input-field-loginpage {
    height: 56px !important;
    margin-bottom: 15px !important;
    border-radius: 6px !important;
}

.logo-page-forresetpassword {
    padding-bottom: 10px;
}

.sigin-button-loginpage {
    width: 100%;
    height: 60px !important;
    margin-top: 50px;
    /* padding: 19px 180px 14px 178px; */
    border-radius: 6px !important;
    background-color: #296ce7 !important;
}

.sign-loginpage {

    font-family: 'NunitoSans SemiBold';
    font-size: 20px;
    line-height: 1.35;
    color: #fff;
}

.mrt-3 {
    margin-right: -3px !important;
}

.forgot-password-text-login a:active {
    color: #333 !important;
    cursor: pointer;
}

.forgot-password-text-login a {
    color: #333 !important;
    cursor: pointer;
    text-decoration: none !important;
}


.forgot-password-text-login {

    font-family: 'NunitoSans Regular';
    font-size: 14px;
    line-height: 1.36;
    color: #333;
}


.password-input-field-loginpaged {
    height: 56px !important;
    margin-bottom: 12px !important;

    border-radius: 6px !important;
}

.brand-wrapper-login h1 {

    font-family: 'NunitoSans SemiBold';
    font-size: 36px;
    line-height: 1.36;
    color: #000;
    margin-bottom: 0px;
}

.form-page-login {
    margin-left: 110px;
    margin-right: 110px;
}

.email-address-login {
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    line-height: 1.38;
    color: #313131;
    margin-bottom: 10px;
    display: inline-block;
}

.password-loginpage {
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    line-height: 1.38;
    color: #313131;
    margin-bottom: 10px;
    display: inline-block;
}

.login-carousel .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
    display: none;
}

.image-background-color-loginpage {
    background-color: #0055c1;
    height: 100vh;
    /* position: fixed; */
    max-width: 100%;
    width: 100%;
}

.image-scorll-loginpage {
    width: 457px !important;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-top: 123px;
    height: 70%;
}

.Stay-Organized {
    font-family: 'Inter Bold';
    font-size: 40px;
    line-height: 1.23;
    color: #fff;
    margin-bottom: 23px;
    margin-top: 29px;
}

.Save-time {
    font-family: 'Inter Regular';
    font-size: 20px;
    line-height: 1.5;
    color: #fff;
    padding: 0 10px;
}

.login-carousel .carousel .control-dots {
    margin: 12px 0 !important;
}

.tag-login {
    margin-top: 80px;
    text-align: start;
    margin-left: 15px;
    margin-bottom: 12px;
    font-family: 'NunitoSans Regular';
    font-size: 12px;
    line-height: 1.33;
    color: #fff;
}

.login-carousel .carousel .control-dots .dot.selected,
.carousel .control-dots .dot:active {
    cursor: pointer;
    display: inline-block;
    margin: 0 5px 0 0 !important;
    width: 24px !important;
    height: 8px !important;
    border-radius: 4px !important;
    background: white !important;
}

.login-carousel .carousel .control-dots .dot {
    cursor: pointer;
    display: inline-block;
    width: 14px !important;
    height: 8px !important;
    margin: 0 5px 0 0 !important;
    opacity: 0.5;
    border-radius: 4px !important;
}

.login-carousel .carousel.carousel-slider .control-arrow {
    display: none;

}

.login-carousel .carousel .carousel-status {
    display: none;

}

@media(max-width:1383px) {
    .image-scorll-loginpage {

        padding-top: 49px;
        width: 100% !important;
    }

    .brand-wrapper-login {
        padding-top: 42px;
    }
}

@media(max-width:1024px) {
    .brand-wrapper-login {
        padding-right: 10px;
        padding-left: 10px;
    }

    .form-page-login {
        margin-left: 10px;
        margin-right: 10px;
    }

    .Stay-Organized {
        font-size: 25px;
    }

    .Save-time {
        font-size: 16px;
    }

}

@media(max-width:767px) {
    .Vieew-Details-Price-plan-Days-Card .ant-btn {
        display: block;
        margin: auto;
        width: auto !important;
        height: auto !important;
    }

    .Vieew-Details-Price-plan-Days .ant-btn {
        width: auto !important;
        height: auto !important;
    }

    .edit-profile {

        font-size: 13px !important;
    }

    .edit-profile {
        font-size: 13px !important;
    }

    .edit-profile-Card {
        font-size: 14px !important;
    }

    .logo-loginpage {
        margin: auto;
        height: 55px;
        display: block;
    }

    .logo-page-forresetpassword {
        margin: auto;
        display: block;
    }

    .image-background-color-loginpage {
        height: 79vh !important;
    }

    .Stay-Organized {
        font-size: 17px !important;
        margin-bottom: 9px;
        margin-top: 21px;
    }

    .Save-time {
        font-size: 14px !important;
    }

    .tag-login {
        margin-top: 30px !important;
        text-align: center !important;
        line-height: normal !important;
        padding-bottom: 28px !important;
    }


    .sigin-button-loginpage {
        margin-bottom: 10px !important;
    }


    .image-scorll-loginpage {
        width: 78% !important;
        padding-top: 97px;
        height: 312px !important;
    }

    .brand-wrapper-login {
        padding-top: 34px;
    }
}


@media(max-width:768px) {
    .active-users {
        margin-top: -43px !important;
    }

    .inactive-users {
        margin-top: -24px !important;
    }
}

@media (max-width:425px) {
    .image-background-color-loginpage {
        height: 56vh !important;
    }

    .image-scorll-loginpage {
        height: 182px !important;
    }
}

@media(max-width:441px) {
    .First-Add-Client-Page {
        font-size: 14px !important;
    }

    .home-Ad-Events_pages {
        font-size: 12px !important;
    }

    .Priceandplan-Ad-Events_pages {
        font-size: 12px !important;
    }

    .breadcumbs-design {
        margin-top: 0px !important;
    }

    .create-plan-Ad-Events_pages {
        font-size: 12px !important;
    }

    .Roles-Pages {
        font-size: 15px !important;
    }

    .Roles-Add-Pages-MainClass {
        font-size: 12px !important;
    }

    .Roles-Pages-Settings {
        font-size: 12px !important;
    }

    .Edit-profile-Roles {
        font-size: 12px !important;
    }
}

@media(max-width:1199px) {
    .showafter {
        display: none;
    }

    .showbefore {
        display: block;
    }
}