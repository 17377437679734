.Rectangle-260 {
  width: 100%;
  padding: 24px 28px 24px 28px;
  background-color: white;
}

.Add-team-menbers {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 9px;
  width: 191px;
  border: none;
  cursor: pointer;
  border-color: white;
  height: 46px;
  background-color: #346CE7;
  border-radius: 6px;
}

.yo-can-alway {
  color: black;
  margin-top: 21px;
  font-size: 15px;
}

.skip-now {
  text-align: center;
  color: blue;
  cursor: pointer;
  margin-top: 10px;
}

.image-popup {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 65%;
}

.StripeElement,
.StripeElementIdeal {
  display: block;
  margin: 0px 0 8px;

  border-radius: 4px;

  border: solid 1px #dadada !important;
  background-color: #fff;
  height: 44px;
  padding: 10px 10px 10px 10px;

}

.Pay-button {
  color: white !important;
  cursor: pointer;
  /* padding:8px 8px 8px 8px; */
  height: 37px;
  border: 1px solid #346CE7;
  background-color: #346CE7;

}


.error-credit {
  color: black;
  font-size: 14px;
  margin-bottom: 10px;
}

.cardetails {
  color: white;
  font-size: 14px;
}

.Price-Form .ant-modal-footer {
  display: none;
}

.Price-Form .ant-modal-body {
  background-color: white;
}

.Price-Form .ant-modal-close-x {
  margin-top: 27px;
}

.Rectangle-Enterpriseplan {
  width: 100%;
  padding: 24px 28px 24px 28px;
  background-color: white;
}

.Basic-Team {
  margin: 0 0px 14px 0px;
  font-family: 'NunitoSans SemiBold';
  font-size: 22px;
  font-weight: 600;
  line-height: 1.31;
  color: #333;
}

.fw-bold {
  font-weight: bold;
}

.Rectangle-For-contactus {
  width: 191px;
  border: none;
  border-color: white;
  cursor: pointer;
  height: 46px;
  margin: 40px 0 83px 0px;
  border-radius: 6px;
  background-color: #296ce7;
}

.buy-now {
  font-family: 'NunitoSans SemiBold';
  font-size: 14px;
  color: #fff
}

.Rectangle-276 {
  width: 191px;
  border: none;
  cursor: pointer;
  border-color: white;
  height: 46px;
  margin: 0 0 0 0px;
  border-radius: 6px;
  background-color: #296ce7;
}
.Rectangle-276-disabled {
  background-color: #296ce79e;
  cursor: default;
}

.Plan-text-teams-plans {
  margin: 20px 0px 0px 0px;
  font-family: 'NunitoSans Regular';
  font-size: 14px;
  font-weight: 500;
  line-height: 2.13;
  color: #000;
  padding-right: 11px;
}

.mr-bottom-1000 {
  margin-bottom: 100px;
}

.unlimited-events {
  margin: 0 0 9px 10px;
  font-family: 'NunitoSans Regular';
  font-size: 16px;
  color: #333;
}

.Basic-Team-Price {
  margin: 0 0px 14px 0px;
  font-family: 'NunitoSans Bold';
  font-size: 30px;
  font-weight: 600;
  line-height: 1.53;
  color: #333;
}

.Paid-Annually {
  margin: 0px 0px 83px 0px;
  font-family: 'NunitoSans Regular';
  font-size: 17px;
  font-weight: 500;

  color: #000;
}

.user-month {
  margin: 0px 0px 7px 0px;
  font-family: 'NunitoSans Regular';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7;
  color: #296ce7;
}

.tick-marker {
  width: 13px;
  height: 22px;
  object-fit: contain;
}

.price-marker {
  width: 17px;
  height: 22px;
  object-fit: contain;
}

.Path-221 {
  height: 402.9px;
  padding: 21px 26px 30.9px 27px;
  background-color: #fff;
}

.Path-new {
  height: 100px;
  padding: 21px 26px 30.9px 27px;
  background-color: #fff;
}

.Path-226 {
  height: 402.9px;
  padding: 20px 10px 24.9px 15.5px;
  background-color: #fff;
}

.Path-330 {
  height: 402.9px;
  padding: 20px 15.5px 24.9px 15.5px;
  background-color: #fff;
}

.chevron-down-1 {
  width: 16px;
  height: 9px;
  margin: 5.5px 0 0px 0px;
  object-fit: contain;
}

.Billing-Details {
  font-family: 'NunitoSans Regular';
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #333;
  margin-bottom: 27px;
}

.EventsName-text {
  font-family: 'NunitoSans SemiBold';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  display: block;
  color: #525252;
}

.Checkout-Button .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 56px;
  padding: 13px 16px;
}

.Checkout-Button .ant-select {
  width: 100%;
  height: 49px;
  margin: 9px 0 0px 0px;
  background-color: #fff;
}

.Checkout-Button .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 4px;
  padding-top: 3px;
  font-family: 'NunitoSans Regular';
  font-size: 16px;

  line-height: 1.38;
  opacity: 0.3;
  color: #000;
}

.Order-Summary {

  font-family: 'NunitoSans Bold';
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  color: #525252;
}


.Events-InputFields-Data .ant-input {
  height: 50px;
  margin: 12px 0px 8px 0px;
  padding: 17px 0px 17px 17px;
  border-radius: 4px;
  border: solid 1px #dadada;
  background-color: #fff;
  width: 100%;

}

.Events-InputFields-Data .ant-input:placeholder-shown {
  font-family: 'NunitoSans Regular';
  font-size: 16px;
  line-height: 1.38;
  color: #707070 !important;
}

.Item-1-Handling-Tax-collected {

  font-family: 'NunitoSans Regular';
  font-size: 16px;
  line-height: 1.88;
  color: #525252;
}

.Item {
  margin-top: 27px;
}

.Line-style {

  margin: 22px 0px 18px 0;
  border: solid 1px #e2eafd;
}

.Order-Total {

  margin: 0px 0px 19px 0px;
  font-family: 'NunitoSans Bold';
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #525252;
}

.By-placing-your-order-you-agree-to-our-company-Privacy-Policy-and-Conditions-of-use {
  margin: 0px 0 21px 0px;
  font-family: 'NunitoSans Regular';
  font-size: 14px;
  line-height: 1.57;
  color: #525252;
}

.By-style {
  margin: 0px 0 0px 0px;
  display: inline-block;
  font-family: 'NunitoSans Regular';
  font-size: 14px;
  line-height: 1.57;
  color: #296ce6;
}

.Place-Order {
  font-family: 'NunitoSans Bold';
  font-size: 18px;
  font-weight: bold;

  line-height: 1.33;
  color: #fff;
}

.Rectangle-250 .ant-btn {
  height: 56px;
  margin: 0px 11px 0 4.5px;
  /* padding: 16px 75px; */
  width: 100%;
  border-radius: 6px;
  background-color: #296ce6;
}

@media(max-width:991px) {
  .enterprise-plan-top {
    margin-top: 42px;
  }
}