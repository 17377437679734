
.View-details-Price-tex {
    margin: 5.5px 0px 12px 0;
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #525252;
}
.price {
    margin: 12px 0px 40px 0;
    font-family: 'NunitoSans SemiBold';
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    color: #000;
}

.Price-Loreum-text {
    margin: 65px 0px 23.5px 0.5px;
    font-family: 'NunitoSans Bold';
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
}


.View-Details-Plan-Border {
    width: 100%;
    height: auto;
    padding: 41px 35px 47px;
    background-color: #fff;
    margin: 0px 20px 0px 0px;
    padding: 34.5px 34px 10px 35.5px;
}

.Vieew-Details-Price-plan-Days .ant-btn {
    /* width: 120px; */
    height: 50px;
    padding: 14px 20px 14px 21px;
    border-radius: 6px;
    background-color: #296ce6;
    margin-top: 25px;
}

.footer-styling-View-Details-Page {
    margin: 40px 0px 19px 0px;
    opacity: 0.5;
    font-family: 'NunitoSans Regular';
    font-size: 12px;
    line-height: 1.33;
    color: #000;
}

.Plan-View-Details-Pages {
    font-family: 'NunitoSans Bold';
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    color: #000;
}

.edit-profile {
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #fff;
}

.profile-image-set {
    width: 121px;
    height: 121px;
    margin: 0 40px 0px 0px;
    border-radius: 8px;
    border: 1px solid rgb(241, 241, 241);
    padding: 5px;
}

.Chetan-Hegde {
    margin: 0 0 8px;
    font-family: 'NunitoSans Bold';
    font-size: 30px;
    font-weight: bold;
    line-height: 1.37;
    color: #000;
    display: inline-block;
}

.chetangeekyantscom {

    font-family: 'NunitoSans Regular';
    font-size: 18px;
    line-height: 1.33;
}

.mr-top-20 {
    margin-top: 20px;
}

.password-input-field-forgot-password {
    height: 56px !important;
    margin-bottom: 0px !important;
    border-radius: 4px;
    background-color: #f3f5fa !important;
}

.password-input-field-forgot-password .ant-input-suffix {
    font-size: 18px;
}

.password-fields-for-profiles .ant-input-affix-wrapper>input.ant-input {
    background-color: #f3f5fa !important;
}

.password-fields-for-profiles .ant-input-affix-wrapper {
    border: none;
}

.change-passsord-profile-page {
    margin: 15px 0 15px 10px;
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    text-decoration: underline;
    cursor: pointer;
    color: #296ce7;
}


/* View Modal */

.Profile-Assign-modal .ant-modal-close-x {
    margin-top: 40px;
    font-size: 23px;
    margin-right: 29px;
    color: #5b5b5b;
}

.Profile-Assign-modal .ant-modal-body {
    padding-left: 50px;
    padding-top: 36px;
    padding-right: 48.9px;
}

.Profile-Assign {
    font-family: 'NunitoSans Bold';
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    margin-bottom: 36px;
    color: #000;
}

.Profile-Assign-modal .ant-modal-content {
    border-radius: 10px;
}

.Profile-Assign-modal .ant-modal-footer {
    display: none;
}

.Profile-Page-Modal .Cancel-button-Profile .Button-Cancel-Profile {
    /* margin-left: 108px; */
    margin-right: 20px;
    width: 142px;
    height: 60px;
    padding: 17px 40px 16px;
    border-radius: 6px;
    background-color: #f3f5fa;
    cursor: pointer;

}

.Profile-Page-Modal .Cancel-Profile {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #5a5a5a;
}

.Profile-Page-Modal .Create-button-Profile .Button-Create-Profile {
    cursor: pointer;
    width: 140px;
    height: 60px;
    padding: 17px 19px 16px 40px;
    border-radius: 6px;
    background-color: #296ce6;
}

.Profile-Page-Modal .Create-Profile {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #ffffff;
}

.Profile-SectionPrices {
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #313131;
    margin-bottom: 13px;
}
.Profile-Section-Price .ant-input {
    width: 409px;
    height: 27px;
    margin: 7px 0px 7px 0;
    border-radius: 4px;
    background-color: #fff;
}
.Profile-Section-Price .ant-input:placeholder-shown {
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #313131;
}
.mr-top-73 {
    margin-top: 33px;
}
/* @media(max-width:991px) {
    .profile-image-set {
        width: 100%;
        height: auto;
    }
} */