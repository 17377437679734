.set-loader {
    position: fixed;
    z-index: 99999;
    top: 57%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    right: 0;
    stroke: #66b6e0;
    fill: #66b6e0;
}


.err-message-email-password {
    color: red;
    margin-bottom: 10px;
}

.dont-have-account {
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    line-height: 1.38;
    color: #313131;
    margin-bottom: 22px;
}

.brandwrapper-signup {
    padding-top: 23px;
    padding-left: 20px;
    padding-bottom: 4px;
}

.emial-address-signup-page {
    height: 37px !important;
    margin-bottom: 15px !important;
    border-radius: 6px !important;
}

.password-filed-signup-page {
    height: 37px !important;
    margin-bottom: 12px !important;
    border-radius: 6px !important;
}

.phoneinputfield .react-tel-input .form-control {
    width: 100% !important;
}

.brandwrapper-signup h1 {
    font-family: 'NunitoSans SemiBold';
    font-size: 26px;
    color: #000;
    margin-bottom: 0px;
}

.signup-page-login {
    margin-left: 26px;
    margin-right: 26px;
}

.logo-loginpage {
    padding-bottom: 20.1px;
}

.Super-Create-button .Button-Super-Create {
    border-radius: 6px;
    background-color: #296ce6;
    cursor: pointer;
    display: inline;
    padding-top: 15px;
    padding-bottom: 12px;
    padding-left: 22px;
    padding-right: 22px;
}

.Super-Create {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #ffffff;
}

@media(max-width:1383px) {
    .brandwrapper-signup {
        padding-top: 42px;
    }
}

@media(max-width:1024px) {
    .brandwrapper-signup {
        padding-right: 10px;
        padding-left: 10px;
    }

    .signup-page-login {
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media(max-width:767px) {
    .brandwrapper-signup {
        padding-top: 34px;
    }
}