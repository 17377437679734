.mr-10-right {
    margin-right: 10px;
}

.display-value {
    display: flex;
}

.width-select-class {
    width: 150px;
}

.bg-colors-grey {
    background-color: lightgrey;
}

.all-borders {
    margin: 0 8px 0px 0px;
    padding: 3px 11px 5px 12px;
    border-radius: 4px;
    background-color: #fff3db;
}

.all-borders-chat {
    height: 24px;
    margin: 0 8px 0px 0px;
    padding: 3px 10px 5px 12px;
    border-radius: 4px;
    background-color: #ffdee2;
}

.all-borders-call {
    height: 24px;
    margin: 0 8px 0px 0px;
    padding: 3px 11px 5px 12px;
    border-radius: 4px;
    background-color: #ece0fe;
}

.all-borders-create {
    height: 24px;
    margin: 0 8px 0px 0px;
    padding: 3px 11px 5px 12px;
    border-radius: 4px;
    background-color: #f2f5f9;
}

.Roles-Managemenet-Serach .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    font-size: 14px;
    font-family: 'NunitoSans SemiBold';
    color: black;
}

.Roles-Managemenet-Serach .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    min-width: 0;
    width: 182px !important;
    height: 40px !important;
    margin: 0px 20px 0px 10px !important;
    padding: 9px 10px 9px 12px !important;
    border-radius: 6px !important;
    background-color: #fff !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border: 1px solid #fff !important;
}


.Roles-Managemenet-Serach .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid white !important;
    border-radius: 6px !important;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 38px !important;
    padding: 6px 11px 0px 10px !important;
}

.Roles-Managemenet-Serach .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    font-size: 14px;
    font-family: 'NunitoSans SemiBold';
    color: black;
}


.Roles-Managemenet-Serach .ant-input {
    font-size: 16px;
    font-family: 'NunitoSans Regular';
    color: #707070 !important;
    height: 19px !important;
}

.delete-icon-Roles {
    width: 18px;
    height: 20px;
    object-fit: contain;
    cursor: pointer;
}

.Day-week-buttons-Price-Roles .ant-btn {
    height: 40px;
    padding: 11px 9px 10px 11px;
    border-radius: 6px;
    background-color: #296ce7;
}

/* View Modal */

.Modal-Button .Cancel-button .Button-Cancel {
    /* margin-left: 612px; */
    margin-right: 20px;
    width: 142px;
    height: 60px;
    padding: 17px 40px 16px;
    border-radius: 6px;
    background-color: #f3f5fa;
    cursor: pointer;
}

.Modal-Button .Cancel {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #5a5a5a;
}

.Modal-Button .Create-button .Button-Create {
    cursor: pointer;
    width: 140px;
    height: 60px;

    padding: 17px 19px 16px 40px;
    border-radius: 6px;
    background-color: #296ce6;
}

.Modal-Button .Create {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #ffffff;
}

.mr-top-100 {
    margin-top: 50px;
}



.Main-checkboxed-style .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #296ce6;
    border-color: #296ce6;
}

.Main-checkboxed-style .ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 24px;
    height: 24px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.Main-checkboxed-style .ant-checkbox-inner::after {
    /* position: absolute; */
    top: 45%;
    left: 21%;
    /* display: table;
    width: 6.714286px;
    height: 14.142857px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0; */
    /* -webkit-transform: rotate(
45deg
) scale(0) translate(-50%, -50%);
    transform: rotate(
45deg
) scale(0) translate(-50%, -50%);
 
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: ' '; */
}

.Confirm-Delete .ant-modal-content {
    border-radius: 10px;
}

.Confirm-Delete .ant-modal-footer {
    display: none;
}

.Confirm-Delete .Cancel-button .Button-Cancel {
    width: 109px;
    cursor: pointer;
    height: 40px;
    margin: 0px 18px 13px 243px;
    padding: 7px 34px 6px 35px;
    border-radius: 3px;
    background-color: #f3f5fa;
}

.Confirm-Delete .Cancel {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #5a5a5a;
}

.Confirm-Delete .Create-button .Button-Create {
    cursor: pointer;
    width: 100px;
    height: 40px;
    margin: 0px 0px 0;
    padding: 7px 39px 6px 35px;
    border-radius: 3px;
    background-color: #296ce6;
}

.Confirm-Delete .Create-button .Button-Create-cancel {
    cursor: pointer;
    width: 105px;
    height: 40px;
    margin: 3px 29px 0;
    padding: 7px 0px 6px 19px;
    border-radius: 3px;
    background-color: #296ce6;
}

.Confirm-Delete .Create {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #ffffff;
}


.Confirm-Delete .are-you-select
{
    margin: 0px 0px 19px 31px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
     line-height: 1.35;
    color: #333;
}

.Confirm-Delete-fucntio {
    height: 80px;
    margin: 0 0 29px;
    padding: 25px 0px 23px 28px;
    background-color: #f3f5fa;
}

.confirm-delete-text {
    font-family: 'NunitoSans Bold';
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
}

.Confirm-Delete .ant-modal-close-x {
    display: none !important;
}

.Confirm-Delete .ant-modal-body {
    padding: 3px !important;
}


.ant-select-multiple .ant-select-selector {
    min-height: 56px;
    height: auto !important;
}

.status-filter-title {
    border-top-left-radius: 2px;
    font-family: 'NunitoSans Bold';
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    color: #333;
    display: block;
    margin-right: 10px;
}

/* .ant-picker-time-panel-column::after {
    height: auto !important;
} */

@media (max-width: 991px) {

    .Confirm-Delete .Cancel-button .Button-Cancel {
        margin: 0 18px 13px 23px;
    }

    .Roles-Managemenet-Serach .ant-input-affix-wrapper {
        width: 100% !important;
        margin: 0 0px 10px 0px !important;
    }

    .Day-week-buttons-Price-Roles .ant-btn {
        width: 100%;
    }

    .display-value {
        display: block;
    }

    .width-select-class {
        width: 887px;
    }

    .Roles-Managemenet-Serach .ant-select {
        margin-right: 0px !important;
        margin-bottom: 10px;
    }

}

